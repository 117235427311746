<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useListTable from '@/comp-functions/useListTable';
import {formatNumber} from '@/utils/formatter'
import { tableData } from "./dataPorttable";
import moment from 'moment'
import DatePicker from "vue2-datepicker";
import printInvoiceSmu from '@/components/print-invoice-smu'
import { ref, onMounted } from '@vue/composition-api'
import useHttp from '@/comp-functions/useHttp'
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Order List",
    meta: [{ name: "description", content: appConfig.description }]
  },

  setup () {
    const { 
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists } = useListTable({ url: false })
      const tableColumns = [
        { key: "ord_datetime", label: "Order Date", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "col_ordno", label: "Order No.", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "schedule.vendor.name", label: "Airline", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "schedule.sch_departure_datetime", label: "Departure", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "ord_manifestno", label: "SMU No.", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "ord_weight", label: "Total Weight", sortable: true, tdClass: "text-right",
          formatter: value => {return formatNumber(value)}
        },
        {key: 'ord_gtotalcharge', label: 'Grand Total Charge', tdClass: "text-right",
          formatter: value => {return formatNumber(value)}
        },
        { key: "", label: "Payment Status", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "", label: "Goods Status", thClass: 'text-center', tdClass: 'text-center', sortable: true },
        { key: "action", thClass: 'text-center', tdClass: 'text-center',label: "#" }
    ]

    const columnToBeFilter = [
       'ord_datetime',
      'col_ordno',
      'schedule.vendor.name',
      'schedule.sch_departure_datetime',
      'ord_manifestno',
      'ord_weight',
      'ord_gtotalcharge'

    ]

    const { $get } = useHttp()

const form = ref({
      customer: '',
      
      })
    
     const LOV = ref({
       customer: [],

     })

      const getCustomer = async () => { 
      const {data} = await $get({ url: 'master/customers'})
      LOV.value.customer = data

      }

     const date1 = new Date()
    const date2 = new Date()
    date1.setDate(date2.getDate() - 23);

    const paramdate = ref([moment(date1).format('DD-MM-YYYY'), moment(date2).format('DD-MM-YYYY')])
    console.log(paramdate)

    const reloaddatalist = async () => {
      let url = '/master/order'
      let params = {
        start_orddate: paramdate.value[0],
        end_orddate:  paramdate.value[1],
        customer_id:  form.value.customer.id,
      }
      await fetchLists(url,params)
    }
    onMounted(() => {
      reloaddatalist()
      getCustomer()
    })

    const createSmu = async (inv_id) => {
      printInvoiceSmu(inv_id)
    }

     return {
      formatNumber,
      tableColumns,
      columnToBeFilter,
      paramdate,
      reloaddatalist,
      form,
      LOV,
      getCustomer,
      
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      createSmu,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists
    }
  },
  components: { Layout, PageHeader, DatePicker, Multiselect },
  data() {
    return {
      tableData: tableData,
      title: "Order List",
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "derparture_date",
      sortDesc: false,
      select_label: "Select"
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalList = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <label class="col-md-10 col-sm-12 d-inline-flex align-items-center p-0">
                  Order Date :
                  <date-picker class="col-md-7" @close="reloaddatalist()"  v-model="paramdate" format='DD-MM-YYYY' valueType="format"  range append-to-body lang="en"></date-picker>
                </label>
                 <label class="col-md-10 col-sm-12 d-inline-flex align-items-center p-0">
                  Customer :
                   <multiselect class="col-md-7" @close="reloaddatalist()"  v-model="form.customer" :options="LOV.customer" label="name" track-by="name" :select-label="select_label" ></multiselect>
                   <!-- <multiselect v-model="form.customer" :options="LOV.customer" label="name" track-by="name" :select-label="select_label" ></multiselect> -->
                </label>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="searchQuery"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
               id="refListTable"
                ref="refListTable"
                no-provider-paging
                no-provider-filtering
                :filter-included-fields="columnToBeFilter"
                :fields="tableColumns"
                :items="itemLists"
                :per-page="perPage"
                :current-page="currentPage"
                :filter="searchQuery"
                responsive
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :busy="isBusy"
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
                class="position-relative"
              >
                 <template v-slot:cell(action)="data">
                  <router-link v-if="data.item.ord_manifestno === null && data.item.payment.length > 0"
                    tag="a"
                    
                    class="btn btn-sm btn-primary"
                    v-b-tooltip.hover title="Input SMU No."
                  >Input SMU No.</router-link>
                  <b-button-group vertical  v-if="data.item.payment.length > 0">
                    <b-dropdown variant="light">
                      <template slot="button-content">
                        Action
                        <i class="mdi mdi-chevron-down"></i>
                      </template>
                      <b-dropdown-item
                      v-if="data.item.ord_manifestno === null && data.item.payment.length > 0"
                      :to="{name: 'apps-manageorder-inputsmuno', params: {order_id : data.item.ord_id }}"
                      >Input SMU#</b-dropdown-item>
                      <b-dropdown-item
                        v-if="data.item.payment.length > 0"
                        :to="{name: 'apps-manageorder-verification', params: {orderid : data.item.ord_id }}"
                      >Verification</b-dropdown-item>
                      <b-dropdown-item
                        v-if="data.item.payment.length > 0"
                        :to="{name: 'apps-billing-invoiceadjustment', params: {orderid : data.item.ord_id }}"
                      >Create Invoice</b-dropdown-item>
                      <b-dropdown-item
                      v-if="data.item.payment.length > 0"
                      @click="createSmu(data.item.ord_id)"
                      >Create SMU</b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                  <div v-if="data.item.ord_manifestno === null && data.item.payment.length === 0" class="badge badge-soft-danger font-size-13">
                    UNPAID
                  </div>
                </template>
                
                 <template v-slot:cell(col_ordno)="data">
                   <router-link :to="{ name: 'apps-order-detail', params: { p_ordno: data.item.ord_no }}">{{data.item.ord_no}}</router-link>
                </template>
                </b-table>
            </div>
            <div class="row">
               <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                   Showing
                    <b-form-select class="col-sm-12 col-md-2" v-model="perPage" size="sm" :options="perPageOptions"></b-form-select>{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
                  </label>
                </div>
              </div>
              
              <div class="col-md-6 col-xs-12">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
