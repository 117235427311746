const tableData = [
    {
      custinv_date : '09-FEB-2021',
      custinv_orderno : 'BOOK0001',
      custinv_smno : '000-012345678',
      custinv_airline : 'Tri-MG',
      custinv_totalweight : '80',
      custinv_totalcharge : '2.000.000',
      custinv_paymentstatus : 'Unpaid - Adjustment',
      custinv_goodsstatus : 'Verification'
    }
];

export { tableData };
